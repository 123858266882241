const vendors = [{
    "name":"에볼루션 카지노",
    "key":"evolution_casino",
    "limits": [
        {"key": "lobby100", "amount": "1,000 ~ 1,000,000"},
        {"key": "lobby300", "amount": "1,000 ~ 3,000,000"},
        {"key": "lobby500", "amount": "1,000 ~ 5,000,000"},
        {"key": "lobby1000", "amount": "1,000 ~ 10,000,000"},
        {"key": "lobby2000", "amount": "1,000 ~ 20,000,000"},
        {"key": "lobby3000", "amount": "1,000 ~ 30,000,000"},
        {"key": "lobby5000", "amount": "1,000 ~ 50,000,000"},
        {"key": "lobby10000", "amount": "1,000 ~ 100,000,000"},
    ]
},{
    "name":"드림게이밍 카지노",
    "key":"dreamgaming_casino",
    "limits": [
        {"key": "lobby100", "amount": "1,000 ~ 1,000,000"},
        {"key": "lobby300", "amount": "1,000 ~ 3,000,000"},
        {"key": "lobby500", "amount": "1,000 ~ 5,000,000"},
        {"key": "lobby1000", "amount": "1,000 ~ 10,000,000"},
        {"key": "lobby2000", "amount": "1,000 ~ 20,000,000"}
    ]
},{
    "name":"두윈 카지노",
    "key":"duwon_casino",
    "limits": [
        {"key": "lobby100", "amount": "1,000 ~ 1,000,000"},
        {"key": "lobby300", "amount": "1,000 ~ 3,000,000"},
        {"key": "lobby500", "amount": "1,000 ~ 5,000,000"},
        {"key": "lobby1000", "amount": "1,000 ~ 10,000,000"},
        {"key": "lobby2000", "amount": "1,000 ~ 20,000,000"},
        {"key": "lobby3000", "amount": "1,000 ~ 30,000,000"},
        {"key": "lobby5000", "amount": "1,000 ~ 50,000,000"}
    ]
},{
    "name":"WM 카지노",
    "key":"wm_casino",
    "limits": [
        {"key": "lobby100", "amount": "10,000 ~ 1,000,000"},
        {"key": "lobby300", "amount": "30,000 ~ 3,000,000"},
        {"key": "lobby500", "amount": "50,000 ~ 5,000,000"},
        {"key": "lobby1000", "amount": "100,000 ~ 10,000,000"},
        {"key": "lobby2000", "amount": "200,000 ~ 20,000,000"},
        {"key": "lobby3000", "amount": "300,000 ~ 30,000,000"},
        {"key": "lobby5000", "amount": "500,000 ~ 50,000,000"},
    ]
},{
    "name":"오리엔탈게이밍 카지노",
    "key":"oriental_casino",
    "limits": [
        {"key": "lobby100", "amount": "1,000 ~ 1,000,000"},
        {"key": "lobby300", "amount": "1,000 ~ 3,000,000"},
        {"key": "lobby500", "amount": "1,000 ~ 5,000,000"},
        {"key": "lobby1000", "amount": "1,000 ~ 10,000,000"},
        {"key": "lobby3000", "amount": "1,000 ~ 30,000,000"},
        {"key": "lobby5000", "amount": "1,000 ~ 50,000,000"}
    ]
},{
    "name":"아시안게이밍 카지노",
    "key":"ag_casino",
    "limits": [
        {"key": "lobby100", "amount": "4,000 ~ 1,000,000"},
        {"key": "lobby1000", "amount": "5,000 ~ 10,000,000"}
    ]
},{
    "name":"빅게이밍 카지노",
    "key":"bg_casino",
    "limits": [
        {"key": "lobby10", "amount": "1,000 ~ 100,000"},
        {"key": "lobby50", "amount": "2,000 ~ 500,000"},
        {"key": "lobby150", "amount": "3,000 ~ 1,500,000"},
        {"key": "lobby200", "amount": "5,000 ~ 2,000,000"},
        {"key": "lobby1000", "amount": "10,000 ~ 10,000,000"},
        {"key": "lobby2000", "amount": "20,000 ~ 20,000,000"}
    ]
},{
    "name":"보타 카지노",
    "key":"bota_casino",
    "limits": [
        {"key": "lobby100", "amount": "1,000 ~ 1,000,000"},
        {"key": "lobby300", "amount": "1,000 ~ 3,000,000"},
        {"key": "lobby500", "amount": "1,000 ~ 5,000,000"},
        {"key": "lobby1000", "amount": "1,000 ~ 10,000,000"},
        {"key": "lobby2000", "amount": "1,000 ~ 20,000,000"},
        {"key": "lobby3000", "amount": "1,000 ~ 30,000,000"},
        {"key": "lobby5000", "amount": "1,000 ~ 50,000,000"},
    ]
}]

const losers = [
    {"name": "에볼루션", "key":"evolution_casino", "active": "y"},
    {"name": "드림게이밍", "key":"dreamgaming_casino", "active": "y"},
    {"name": "마이크로카지노", "key":"microgaming_casino", "active": "n"},
    {"name": "비보카지노", "key":"vivo_casino", "active": "y"},
    {"name": "오리엔탈게임", "key":"oriental_casino", "active": "y"},
    {"name": "프라그마틱카지노", "key":"pragmatic_casino", "active": "n"},
    {"name": "CQ9카지노", "key":"cq9_casino", "active": "y"},
    {"name": "WM카지노", "key":"wm_casino", "active": "n"},
    {"name": "빅게이밍카지노", "key":"bg_casino", "active" : "y"},
    {"name": "이주기", "key":"ezugi_casino", "active": "y"},
    {"name": "아시안게이밍카지노", "key":"ag_casino", "active": "y"},
    {"name": "모티베이션카지노", "key":"mtv_casino", "active": "y"},
    {"name": "베가스카지노", "key":"vegas_casino", "active": "y"},
    {"name": "Taishan카지노", "key":"taishan_casino", "active": "y"},
    {"name": "보타카지노", "key":"bota_casino", "active": "y"},
    {"name": "두윈카지노", "key":"duwon_casino", "active": "y"},
    {"name": "프라그마틱슬롯", "key":"pragmatic_slot", "active": "n"},
    {"name": "릴킹덤", "key":"reelkingdom_slot", "active": "n"},
    {"name": "레드타이거", "key":"redtiger_slot", "active": "y"},
    {"name": "넷엔트", "key":"netent_slot", "active": "y"},
    {"name": "BTG", "key":"btg_slot", "active": "y"},
    {"name": "벳소프트", "key":"betsoft_slot", "active": "y"},
    {"name": "PG소프트", "key":"pgsoft_slot", "active": "n"},
    {"name": "CQ9슬롯", "key":"cq9_slot", "active": "y"},
    {"name": "게임아트", "key":"gameart_slot", "active": "y"},
    {"name": "나가게이밍", "key":"naga_slot", "active": "y"},
    {"name": "에즈펙트", "key":"aspect_slot", "active": "y"},
    {"name": "부운고", "key":"booongo_slot", "active": "n"},
    {"name": "플레이손", "key":"playson_slot", "active": "n"},
    {"name": "플레이스타", "key":"ps_slot", "active": "y"},
    {"name": "스카이윈드", "key":"skywind_slot", "active": "y"},
    {"name": "하바네로", "key":"habanero_slot", "active": "y"},
    {"name": "아시안게이밍슬롯", "key":"ag_slot", "active": "y"},
    {"name": "GMW슬롯", "key":"gmw_slot", "active": "y"},
    {"name": "넥스트스핀", "key":"nextspin_slot", "active": "y"},
    {"name": "노리밋시티", "key":"nlc_slot", "active": "y"},
    {"name": "핵쏘우", "key":"hs_slot", "active": "y"},
    {"name": "아바타UX", "key":"aux_slot", "active": "y"},
    {"name": "레드레이크", "key":"redrake_slot", "active": "n"},
    {"name": "넷게이밍", "key":"netgaming_slot", "active": "y"},
    {"name": "푼타게이밍", "key":"ftg_slot", "active": "y"},
    {"name": "마이크로슬롯", "key":"microgaming_slot", "active": "n"},
    {"name": "퀵스핀", "key":"quickspin_slot", "active": "y"},
    {"name": "플레이엔고", "key":"png_slot", "active": "y"},
    {"name": "블루프린트", "key":"blueprint_slot", "active": "y"},
    {"name": "릴렉스", "key":"relax_slot", "active": "y"},
    {"name": "드래곤소프트", "key":"dragoon_slot", "active": "y"},
    {"name": "스피어헤드", "key":"spearhead_slot", "active": "y"},
    {"name": "엘리시움", "key":"elysium_slot", "active": "y"},
    {"name": "UPG", "key":"upg_slot", "active": "n"},
    {"name": "에보플레이", "key":"evo_slot", "active": "y"},
    {"name": "릴플레이", "key":"reelplay_slot", "active": "y"},
    {"name": "판타즈마", "key":"fantasma_slot", "active": "y"},
    {"name": "부메랑", "key":"boomerang_slot", "active": "y"},
    {"name": "포더플레이어", "key":"4tp_slot", "active": "y"},
]


export {
    vendors,
    losers
}
