<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import { vendors, losers } from './info'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: this.$t('공통 API 소개'),
      vendors,
      losers
    };
  },
  mounted() {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5>머니이동, 심리스에서 공통으로 사용되는 API입니다.</h5>
            <hr />
            <ul>
              <li><label><router-link to="/api/common/vendors">게임사</router-link>:</label> 파트너에게 등록되어 있는 게임사 목록을 확인할 수 있습니다.</li>
              <li><label><router-link to="/api/common/games">게임목록</router-link>:</label> 파트너에게 등록되어 있는 게임 목록을 확인할 수 있습니다.</li>
              <li><label><router-link to="/api/common/partner/balance">파트너 잔액</router-link>:</label> 현재 남아 있는 파트너의 잔액을 확인할 수 있습니다.</li>
              <li><label><router-link to="/api/common/transaction/pragmatic">프라그마틱 상세내역</router-link>:</label> 프라그마틱 게임의 상세내역을 확인할 수 있는 URL을 제공합니다.</li>
              <li><label><router-link to="/api/common/detail">게임 상세 내역 정보(콜백)</router-link>:</label> 에볼루션, 드림게이밍 등 실시간으로 상세내역을 제공하지 않는 게임사의 상세 정보를 수집이 완료되는 즉시 사이트로 정보를 전송합니다.</li>
            </ul>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <h5>게임사별 배팅 상한 금액</h5>
                <div class="small text-danger">* 카지노게임은 gameKey에 의하여 민/맥스가 변경되며 자체적으로 상한금액 설정 불가합니다.</div>
                <div class="table-responsive w-100">
                  <table class="table table-striped">
                    <thead class="table-dark">
                    <tr>
                      <th>게임사명</th>
                      <th>Key</th>
                      <th>로비</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="vendor in vendors" :key="'limit-' + vendor.key">
                      <td>{{ vendor.name }}</td>
                      <td>{{ vendor.key }}</td>
                      <td>
                        <table class="table table-sm">
                          <thead>
                          <tr>
                            <th>gameKey</th>
                            <th>금액</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(limit, index) in vendor.limits" :key="'loser-' + index">
                            <td>{{ limit.key }}</td>
                            <td>{{ limit.amount }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <h5>게임사별 lose 데이터 전송 정보</h5>
                <div class="small text-danger">* 게임사별로 lose 데이터를 전송하지 않는 곳들이 있으니 확인하여 주세요.</div>
                <div class="table-responsive w-100">
                  <table class="table table-striped">
                    <thead class="table-dark">
                    <tr>
                      <th>게임사명</th>
                      <th>Key</th>
                      <th>전송여부</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="vendor in losers" :key="'limit-' + vendor.key">
                      <td>{{ vendor.name }}</td>
                      <td>{{ vendor.key }}</td>
                      <td>
                        <span class="badge bg-danger" v-if="vendor.active === 'n'">N</span>
                        <span class="badge bg-primary" v-if="vendor.active === 'y'">Y</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
